<template>
  <div>
    <VTitle :title="$t('app.tags', 2)" class="route-title" />

    <SearchField
      :is-searching="isSearching"
      hide-advanced-search
      @input="onUpdateSearch"
    />

    <VList
      :rows="data"
      :headers="headers"
      :is-loading="isLoading"
      :pagination="pagination"
      hide-header
      display-pagination
      @click:name="onClickRedirectToTag"
      @click:attestation_count="onClickRedirectToTag"
      @click:collection_count="onClickRedirectToTag"
      @click:evaluation_count="onClickRedirectToTag"
      @click:lesson_count="onClickRedirectToTag"
      @click:training_count="onClickRedirectToTag"
      @update:current_page="onUpdateCurrentPage"
      @update:per_page="onUpdatePerPage"
    >
      <!-- eslint-disable-next-line -->
      <template #item.image>
        <div
          class="w-8 h-8 bg-theme-2 text-theme-1 flex items-center justify-center rounded-full"
        >
          <VAction icon="tag" class="w-4 h-4" />
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.__ACTIONS="{ item }">
        <div class="flex justify-center">
          <VDeleteAction
            :item="item"
            :text-value="item.name"
            :delete-function="onClickDelete"
          />
        </div>
      </template>
    </VList>
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
// Composables
import useRead from "@/composables/useRead";
import useSearch from "@/composables/useSearch";
// Components
import VTitle from "@/components/VTitle";
import VAction from "@/components/tables/VAction";
import SearchField from "@/components/SearchField";
import VList from "@/components/tables/VList";
import VDeleteAction from "@/components/VDeleteAction";

export default {
  components: {
    VAction,
    VTitle,
    SearchField,
    VList,
    VDeleteAction
  },
  setup() {
    // Misc
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();

    // Constants
    const documentTitle = t("app.tags", 2);

    // Computed
    const isInstructor = computed(() => store.getters["user/isInstructor"]);
    const isAdmin = computed(() => store.getters["user/isAdmin"]);
    const isSysAdmin = computed(() => store.getters["user/isSysAdmin"]);
    const headers = computed(() => {
      const canModify = isInstructor.value || isAdmin.value || isSysAdmin.value;
      const headers = [
        {
          value: "image",
          class: "w-10"
        },
        {
          text: t("app.name"),
          value: "name",
          cellClass: "cursor-pointer"
        },
        {
          text: t("app.attestations", 2),
          value: "attestation_count",
          headerClass: "justify-center",
          cellClass: "cursor-pointer text-center"
        },
        {
          text: t("app.collections", 2),
          value: "collection_count",
          headerClass: "justify-center",
          cellClass: "cursor-pointer text-center"
        },
        {
          text: t("app.evaluations", 2),
          value: "evaluation_count",
          headerClass: "justify-center",
          cellClass: "cursor-pointer text-center"
        },
        {
          text: t("app.lessons", 2),
          value: "lesson_count",
          headerClass: "justify-center",
          cellClass: "cursor-pointer text-center"
        },
        {
          text: t("app.trainings", 2),
          value: "training_count",
          headerClass: "justify-center",
          cellClass: "cursor-pointer text-center"
        }
      ];

      if (canModify) {
        headers.push({
          text: t("app.actions"),
          value: "__ACTIONS"
        });
      }

      return headers;
    });

    // Composables
    const { isSearching, onUpdateSearch } = useSearch({
      searchFields: ["name"],
      route: "tags",
      endpoint: "platform.tags"
    });
    const {
      pagination,
      rows: data,
      isLoading,
      onUpdatePerPage,
      onUpdateCurrentPage,
      onClickDelete
    } = useRead({
      endpoint: "platform.tags",
      route: "tags",
      relations: [],
      searchFields: ["name"]
    });

    // Methods
    const onClickRedirectToTag = item => {
      router.push({
        name: "tag",
        params: {
          id: item?.id
        }
      });
    };

    return {
      headers,
      documentTitle,
      onClickRedirectToTag,
      // useRead
      data,
      isLoading,
      pagination,
      onClickDelete,
      onUpdatePerPage,
      onUpdateCurrentPage,
      // useSearch
      isSearching,
      onUpdateSearch
    };
  }
};
</script>
